import React from 'react'
import { graphql } from 'gatsby'

import PageHeader from '../components/PageHeader'
import Layout from '../components/Layout'
import MyContext from '../components/Context'

// Export Template for use in CMS preview
export const AreegTemplate = ({
  title,
  title_ar,
  subtitle,
  subtitle_ar,
  featuredImage,
  areeg_ar,
  areeg_en
}) => {
  return (
    <MyContext.Consumer>
      {cons => {
        if (!cons) {
          var cons = {
            strings: e => e,
            i18n: {
              language: 'ar'
            }
          }
        }
        return (
          <main className="Home">
            <section>
              <div style={{ marginBottom: '20px' }}>
                <PageHeader
                  title={cons.i18n.language == 'en' ? title : title_ar}
                  subtitle={cons.i18n.language == 'en' ? subtitle : subtitle_ar}
                  backgroundImage={featuredImage}
                />
              </div>
              <div
                style={{
                  textAlign: 'center'
                  // display: 'flex',
                  // flexDirection: 'row',
                  // justifyContent: 'space-between',
                  // backgroundColor: 'red',
                  // flexWrap: 'wrap'
                }}
              >
                {(cons.i18n.language == 'en' ? areeg_en : areeg_ar).map(
                  item => (
                    <div
                      style={{
                        marginRight: '10px',
                        // display: 'flex',
                        // flexDirection: 'column',
                        //                     justifyContent: 'center',
                        marginBottom: '30px',
                        borderBottom: '1px solid #d8d7d7'
                      }}
                    >
                      <iframe
                        title={item.site_name}
                        src={
                          'https://www.youtube.com/embed/' +
                          item.site_url.split('v=')[1]
                        }
                        allow="autoplay; encrypted-media"
                        allowFullScreen="allowfullscreen"
                        width="600"
                        height="400"
                        frameBorder="0"
                        style={{
                          width: '100%',
                          maxWidth: '500px',
                          height: '250px'
                        }}
                      ></iframe>

                      <p style={{ textAlign: 'center' }}>{item.site_name}</p>
                    </div>
                  )
                )}
              </div>
            </section>
          </main>
        )
      }}
    </MyContext.Consumer>
  )
}

// Export Default AreegAlSham for front-ends
const Areeg = ({ data: { page } }) => {
  return (
    <Layout meta={page.frontmatter.meta || false}>
      <AreegTemplate
        {...page}
        {...page.frontmatter}
        body={page.html}
        areeg_ar={[].concat(page.frontmatter.areeg_ar).reverse()}
        areeg_en={[].concat(page.frontmatter.areeg_en).reverse()}
      />
    </Layout>
  )
}

export default Areeg

export const pageQuery = graphql`
  query AreegPage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      frontmatter {
        title
        title_ar
        template
        subtitle
        subtitle_ar
        featuredImage
        areeg_ar {
          site_name
          site_url
        }
        areeg_en {
          site_name
          site_url
        }
      }
    }
  }
`
